import { useEffect, useState } from 'react';
import loadImage from '@/storychief/utils/loadImage';

export default function useImageError(url) {
  const [error, setError] = useState(false);
  useEffect(() => {
    if (url) {
      loadImage(url).then(
        () => setError(false),
        () => setError(true),
      );
    }
  }, [url]);
  return error;
}
